import {WarmeStedenEnGemeentenConfig} from '../app/website-configuration/warme-steden-en-gemeente-config';
import {Tenant} from '../app/website-configuration/tenant';

export const environment = {
  production: true,
  preview: false,
  tenant: Tenant.WARME_STEDEN_EN_GEMEENTEN,
  apiUrl: 'https://api.warmestedenengemeenten.net',
  cmsApiUrl: 'https://cms.warmvlaanderen.net/api',
  cmsApiVoorvoegsel: 'wsg',
  applicationTitle: 'Warme Steden & Gemeenten',
  websiteConfiguratie: WarmeStedenEnGemeentenConfig,
  matomoSiteId: '10',
  forumUrl: 'https://forum.warmestedenengemeenten.net',
  auth: {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_EB5iIoYJc',
    userPoolWebClientId: 'gb56r480esd489f32otqjrbps',
    cognitoDomain: 'https://warm-vlaanderen-prd-wsg.auth.eu-central-1.amazoncognito.com'
  },
};
