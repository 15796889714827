import {WebsiteConfiguratie} from './website-configuratie';

export const WarmeStedenEnGemeentenConfig: WebsiteConfiguratie = {
  initialPage: 'inspiratie',
  logo: {
    logoImg: 'Warm-Steden&Gemeenten.svg',
    logoTitle: 'warme steden en gemeenten',
    logoAlt: 'Warme steden en gemeenten logo',
  },
  menu: [
    {
      label: 'Concept',
      websiteRoute: 'inspiratie',
      metaTagDescription: 'Dit is de beschrijving van de inspiratie pagina voor warme steden en gemeenten'
    },
    {
      label: 'Methodiek',
      websiteRoute: 'pijlers',
      metaTagDescription: 'Dit is de beschrijving van de pijlers pagina voor warme steden en gemeenten'
    },
    {
      label: 'Inspiratiebox',
      websiteRoute: 'inspiratiebox',
      metaTagDescription: 'Dit is de beschrijving vn de inspiratiebox pagina voor warme steden en gemeenten'
    },
    {
      label: 'Activiteiten',
      websiteRoute: 'zinactiviteiten',
      metaTagDescription: 'Dit is de beschrijving van de zinactiviteiten pagina voor warme steden en gemeenten'
    },
    {
      label: 'Materiaal',
      websiteRoute: 'materiaal',
      metaTagDescription: 'Dit is de beschrijving van de materiaal pagina voor warme steden en gemeenten'
    },
    {
      label: 'Nieuws',
      websiteRoute: 'nieuws',
      metaTagDescription: 'Dit is de beschrijving van de nieuws pagina voor warme steden en gemeenten'
    }
  ],
  organisatieType: {
    label: 'stad of gemeente',
    labelMeervoud: 'steden of gemeenten',
    warmeTitel: 'Warme Stad of Gemeente',
    warmeTitelMeervoud: 'Warme Steden of Gemeenten',
    beschrijving: 'stad of gemeente'
  },
  zinmeter: {
    rollen: [
      {
        id: 'burgemeester',
        label: 'Burgemeester/schepen'
      },
      {
        id: 'ambtenaar',
        label: 'Ambtenaar binnen de gemeenten'
      },
      {
        id: 'organisatie-zorg-welzijn',
        label: 'Organisatie zorg en/of welzijn'
      },
      {
        id: 'organisatie-jeugd-vrije-tijd',
        label: 'Organisatie jeugd en/of vrije tijd'
      }
    ],
    uitleg: `<div>
    De zinmeter gaat na of er vuur zit in de stad om een Warme Stad te worden. Het draagvlak wordt gemeten.
    Je kan de link van de bevraging kopiëren en doorsturen naar alle belanghebbenden van de stad
    (leerkrachten, directie/bestuur, externe partners). Heb je een actieve ouderraad, dan kan je hen ook betrekken
    bij de zinmeter en opnemen onder de categorie externe partners, net als bv.
    CLB of andere organisaties die betrokken zijn bij de stad. Je kan zelf bepalen in hoeverre dit tot de cultuur
    van jouw stad behoort om ook deze externe partners
    mee te nemen in de bevraging. De resultaten zullen getoond worden op deze pagina van zodra de zinmeter door
    meerdere personen wordt ingevuld.<br><br>

    De zinmeter informeert de stad over het beeld dat men heeft van een Warme Stad de mate waarin men de
    expeditie wilt, de mate waarin de expeditie
    moet en de mate waarin de expeditie kan. De zinmeting heeft pas zin als de deelnemers goed geïnformeerd zijn over
    de inhoud van de warme stad.<br><br>

    De informatie kan men verkrijgen door de <a href="/inspiratie">inspiratiepagina</a> te raadplegen of door een
    <a href="/zinactiviteiten">zinreis of zinspiratiesessie</a> te volgen.
    </div>`,
    woordenwolkHeaders: [
      {
        id: 'open-vraag-droombeeld',
        titel: 'Droom Warme Stad',
        beschrijving: 'Wat zien, voelen en beleven de belanghebbenden als hun Warme Stad werkelijkheid is.'
      },
      {
        id: 'open-vraag-wat-nodig',
        titel: 'Nodig voor Warme Stad',
        beschrijving: 'Wat vindt iedereen dat er nodig is voor een Warme Stad.'
      }
    ]
  },
  zinscan: {
    naam: 'Zelfscan',
    urlNaam: 'zelfscan',
    uitleg: `<div>
    Met de zelfscan kan je de evolutie van jouw proces meten en opvolgen doorheen de tijd.
    We raden je aan om deze scan minstens 1 keer per jaar in te vullen. Ze bevat 6 hoofdstukken met telkens 10 stellingen.
    <br><br>
    Vul deze zelfscan in samen met de projectgroep. Van zodra je de zelfscan invult,
    zie je op deze pagina jouw evolutie aan de hand van een spindiagram.
    Je krijgt ook te zien waar je meer info vindt over de vragen die je beantwoord hebt met ‘nee’.
    </div>`,
    beschrijvingStartZinscan: `Start of vervolledig hier de zelfscan. De vragen zijn gegroepeerd in zes hoofdstukken.
                               Je kan het invullen van de zelfscan onderbreken nadat je een hoofdstuk hebt afgewerkt.`,
    beschrijvingDownloadIngevuldeZinscan: 'Download hier de resultaten van jouw zelfscan en ontdek de evolutie in jullie proces',
    beschrijvingDownloadLegeZinscan: `Wil je op voorhand de antwoorden verzamelen van de projectgroep?
                                      Download de vragen hier, stuur de vragenlijst door en bundel de antwoorden. Vul daarna het resultaat in op de zelfscan.`,
    spinnenwebInfoText: `Deze resultaten tonen de voortgang van jouw proces aan de hand van een spinnenweb. Per hoofdstuk zie je de voortgang op de 10 stellingen.
                         De resultaten van de eerste en de laatste 10 ingevulde zelfscans zullen worden getoond. Deze kan je ook afvinken.`,
  },
  dashboard: {
    adviesgesprekMail: 'tom.vanacker@warmestedenengemeenten.net',
    organisatie: 'Warme Steden en Gemeenten',
    adviesgesprekText: `Bespreek je graag de resultaten met een medewerker van Warme Steden & Gemeenten? Dat kan! Vind
    het Logo van je stad of gemeente en plan een adviesgesprek.`,
    adviesgesprekButtonText: 'Contacteer je Logo',
    startZinmeterBeschrijving: 'Wil je als werkgroep het draagvlak meten? Zorg voor zoveel mogelijk antwoorden. ' +
      'Kopieer hier de link en stuur door naar alle belanghebbenden.'
  },
  beheer: {
    gebruikersLijstTitel: 'Projectgroep',
    gebruikersLijstBeschrijving: `Beheer hier de leden van jullie <b>projectgroep</b> die toegang hebben tot het platform van jullie stad of gemeente.
    De projectgroep bestaat uit een groep enthousiaste medewerkers (uit het lokaal bestuur en verschillende organisaties uit de stad/gemeente)
    die de weg naar een Warme Stad of Gemeente mee willen en kunnen faciliteren.
    Zij werken samen aan een integraal beleid voor veerkrachtsbevordering bij kinderen en jongeren.
    Als je een nieuw lid aan de projectgroep toevoegt, krijgt deze persoon een mail om in te loggen en kan die daarna ook gebruik maken van jullie dashboard.
    `
  },
  tools: {
    beschrijving: `<p>
    Hier kan je heel wat instrumenten uitwisselen die bijdragen tot een Warme Stad of Warme Gemeente.
    <br><br>
    Misschien heb je een instrument om het samenwerken met externen gemakkelijker te maken: een planningsinstrument, een
    vergadersjabloon, een instrument om ieders talent te leren kennen, …<br>
    Of heb je een instrument waarmee je kinderen en jongeren kan betrekken bij het traject of ze zelfs de eigen regie
    kunnen opnemen. Heb je een goede handleiding om een succesvolle actie op poten te zetten?
    <br><br>
    Alles wat je hier deelt is open source en mag door iedereen gebruikt worden. Zo help je anderen op weg naar een nog
    Warmere Stad of Gemeente. Je kan ook korte, opbouwende feedback geven op de instrumenten.
    </p>`
  },
  referenties: {
    titel: 'Inspirerende steden en gemeente',
    beschrijving: ' Hier komen alle referenties voor steden en gemeenten'
  }
};





